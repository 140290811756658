/**-----------------------------------------------------------------------------------------
* Copyright © 2020 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { Component, ElementRef, Renderer2, HostBinding, Input, NgModule } from '@angular/core';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { CommonModule } from '@angular/common';

const SIZE_CLASSES = {
    'small': 'k-badge-sm',
    'medium': 'k-badge-md',
    'large': 'k-badge-lg'
};
/**
 * Represents the [Kendo UI Badge component for Angular]({% slug overview_badge %}).
 * Used to display additional information or status that is related to an element.
 */
class BadgeComponent {
    constructor(element, renderer, localizationService) {
        this.element = element;
        this.renderer = renderer;
        this.localizationService = localizationService;
        this.hostClass = true;
        /**
         * Specifies the size of the Badge.
         *
         * The possible values are:
         * * `small`
         * * `medium` (Default)
         * * `large`
         *
         */
        this.size = 'medium';
        /**
         * Specifies the appearance fill style of the Badge.
         *
         * The possible values are:
         * * `solid` (Default)
         * * `outline`
         *
         */
        this.fill = 'solid';
        /**
         * Specifies the theme color of the Badge.
         * The theme color will be applied as background and border color, while also amending the text color accordingly.
         *
         * The possible values are:
         * * `primary` (Default)&mdash;Applies coloring based on primary theme color.
         * * `secondary`&mdash;Applies coloring based on secondary theme color.
         * * `tertiary`&mdash; Applies coloring based on tertiary theme color.
         * * `inherit`&mdash; Applies inherited coloring value.
         * * `info`&mdash;Applies coloring based on info theme color.
         * * `success`&mdash; Applies coloring based on success theme color.
         * * `warning`&mdash; Applies coloring based on warning theme color.
         * * `error`&mdash; Applies coloring based on error theme color.
         * * `dark`&mdash; Applies coloring based on dark theme color.
         * * `light`&mdash; Applies coloring based on light theme color.
         * * `inverse`&mdash; Applies coloring based on inverted theme color.
         *
         */
        this.themeColor = 'primary';
        /**
         * Specifies the shape of the Badge.
         *
         * The possible values are:
         * * `circle` (Default)&mdash;Applies circular shape on the Badge.
         * * `rectangle`&mdash;Applies rectangular shape on the Badge.
         * * `rounded`&mdash;Applies rounded shape on the Badge.
         * * `dot`&mdash;Applies dot shape on the Badge.
         * * `pill`&mdash;Applies border radius equal to half of the height of the Badge.
         *
         */
        this.shape = 'circle';
        /**
         * Specifies the position of the Badge relative to the edge of the parent container element.
         *
         * The possible values are:
         * * `edge` (Default)&mdash;The center of the Badge is positioned on the edge of the parent container element.
         * * `inside`&mdash;The Badge is entirely positioned inside the edge of the parent container element.
         * * `outside`&mdash;The Badge is entirely positioned outside the edge of the parent container element.
         *
         */
        this.position = 'edge';
        /**
         * Specifies whether or not to render additional `cutout` border around the Badge.
         *
         * The possible values are:
         * * `true`
         * * `false` (Default)
         *
         */
        this.cutoutBorder = false;
        this.badgeClasses = [];
        this.badgeAlign = { vertical: 'top', horizontal: 'end' };
        this.rtl = false;
        this.dynamicRTLSubscription = this.localizationService.changes.subscribe(({ rtl }) => {
            this.rtl = rtl;
            this.direction = this.rtl ? 'rtl' : 'ltr';
        });
    }
    get cutoutBorderClass() {
        return this.cutoutBorder;
    }
    /**
     * Specifies the alignment of the Badge.
     *
     * The possible values are:
     * * `{ vertical: 'top', horizontal: 'end' }` (Default)
     * * `{ vertical: 'top', horizontal: 'start' }`
     * * `{ vertical: 'bottom', horizontal: 'start' }`
     * * `{ vertical: 'bottom', horizontal: 'end' }`
     *
     */
    get align() {
        return this.badgeAlign;
    }
    set align(align) {
        this.badgeAlign = Object.assign(this.badgeAlign, align);
    }
    ngAfterViewInit() {
        if (!this.badgeClasses.length) {
            this.setBadgeClasses();
        }
    }
    ngOnChanges() {
        this.setBadgeClasses();
    }
    ngOnDestroy() {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
    }
    alignClass() {
        return `k-${this.badgeAlign.vertical}-${this.badgeAlign.horizontal}`;
    }
    positionClass() {
        return `k-badge-${this.position}`;
    }
    sizeClass() {
        return SIZE_CLASSES[this.size];
    }
    shapeClass() {
        return `k-badge-${this.shape}`;
    }
    themeColorClass() {
        return `k-badge-${this.themeColor}`;
    }
    fillClass() {
        return `k-badge-${this.fill}`;
    }
    setBadgeClasses() {
        const element = this.element.nativeElement;
        this.badgeClasses.forEach((className) => {
            this.renderer.removeClass(element, className);
        });
        this.badgeClasses = [
            this.themeColorClass(),
            this.fillClass(),
            this.sizeClass(),
            this.shapeClass(),
            this.alignClass(),
            this.positionClass()
        ];
        this.badgeClasses.forEach((className) => {
            this.renderer.addClass(element, className);
        });
    }
}
BadgeComponent.decorators = [
    { type: Component, args: [{
                selector: 'kendo-badge',
                providers: [
                    LocalizationService,
                    {
                        provide: L10N_PREFIX,
                        useValue: 'kendo.badge.component'
                    }
                ],
                template: `<ng-content></ng-content>`
            },] },
];
/** @nocollapse */
BadgeComponent.ctorParameters = () => [
    { type: ElementRef },
    { type: Renderer2 },
    { type: LocalizationService }
];
BadgeComponent.propDecorators = {
    hostClass: [{ type: HostBinding, args: ['class.k-badge',] }],
    cutoutBorderClass: [{ type: HostBinding, args: ['class.k-badge-border-cutout',] }],
    direction: [{ type: HostBinding, args: ['attr.dir',] }],
    align: [{ type: Input }],
    size: [{ type: Input }],
    fill: [{ type: Input }],
    themeColor: [{ type: Input }],
    shape: [{ type: Input }],
    position: [{ type: Input }],
    cutoutBorder: [{ type: Input }]
};

class BadgeContainerComponent {
    constructor(localizationService) {
        this.localizationService = localizationService;
        this.hostClass = true;
        this.rtl = false;
        this.dynamicRTLSubscription = this.localizationService.changes.subscribe(({ rtl }) => {
            this.rtl = rtl;
            this.direction = this.rtl ? 'rtl' : 'ltr';
        });
    }
    ngOnDestroy() {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
    }
}
BadgeContainerComponent.decorators = [
    { type: Component, args: [{
                selector: 'kendo-badge-container',
                providers: [
                    LocalizationService,
                    {
                        provide: L10N_PREFIX,
                        useValue: 'kendo.badge.component'
                    }
                ],
                template: `<ng-content></ng-content>`
            },] },
];
/** @nocollapse */
BadgeContainerComponent.ctorParameters = () => [
    { type: LocalizationService }
];
BadgeContainerComponent.propDecorators = {
    hostClass: [{ type: HostBinding, args: ['class.k-badge-container',] }],
    direction: [{ type: HostBinding, args: ['attr.dir',] }]
};

const SIZE_CLASSES$1 = {
    'small': 'k-loader-sm',
    'medium': 'k-loader-md',
    'large': 'k-loader-lg'
};
const SEGMENT_COUNT = {
    'pulsing': 2,
    'infinite-spinner': 3,
    'converging-spinner': 4
};
const TYPE_CLASSES = {
    'pulsing': 'k-loader-pulsing-2',
    'infinite-spinner': 'k-loader-spinner-3',
    'converging-spinner': 'k-loader-spinner-4'
};
/**
 * Represents the [Kendo UI Loader component for Angular]({% slug overview_loader %}).
 * Displays a Loader that represents an indeterminate wait time.
 */
class LoaderComponent {
    constructor(element, renderer) {
        this.element = element;
        this.renderer = renderer;
        this.hostClass = true;
        this._type = 'pulsing';
        this._themeColor = 'primary';
        this._size = 'medium';
        this.loader = this.element.nativeElement;
    }
    /**
     * Specifies the Loader animation type.
     *
     * The possible values are:
     * - `pulsing` (default)
     * - `infinite-spinner`
     * - `converging-spinner`
     */
    set type(type) {
        this.renderer.removeClass(this.loader, TYPE_CLASSES[this.type]);
        this.renderer.addClass(this.loader, TYPE_CLASSES[type]);
        this._type = type;
    }
    get type() {
        return this._type;
    }
    /**
     * Specifies the theme color of the Loader.
     *
     * The possible values are:
     * * `primary` (Default)&mdash;Applies coloring based on primary theme color.
     * * `secondary`&mdash;Applies coloring based on secondary theme color.
     * * `tertiary`&mdash; Applies coloring based on tertiary theme color.
     * * `info`&mdash;Applies coloring based on info theme color.
     * * `success`&mdash; Applies coloring based on success theme color.
     * * `warning`&mdash; Applies coloring based on warning theme color.
     * * `error`&mdash; Applies coloring based on error theme color.
     * * `dark`&mdash; Applies coloring based on dark theme color.
     * * `light`&mdash; Applies coloring based on light theme color.
     * * `inverse`&mdash; Applies coloring based on inverted theme color.
     *
     */
    set themeColor(themeColor) {
        this.renderer.removeClass(this.loader, `k-loader-${this.themeColor}`);
        this.renderer.addClass(this.loader, `k-loader-${themeColor}`);
        this._themeColor = themeColor;
    }
    get themeColor() {
        return this._themeColor;
    }
    /**
     * Specifies the size of the Loader.
     *
     * The possible values are:
     * * `small`
     * * `medium` (Default)
     * * `large`
     *
     */
    set size(size) {
        this.renderer.removeClass(this.loader, SIZE_CLASSES$1[this.size]);
        this.renderer.addClass(this.loader, SIZE_CLASSES$1[size]);
        this._size = size;
    }
    get size() {
        return this._size;
    }
    ngAfterViewInit() {
        this.setLoaderClasses();
    }
    /**
     * @hidden
     */
    get segmentCount() {
        return new Array(SEGMENT_COUNT[this.type]);
    }
    setLoaderClasses() {
        this.renderer.addClass(this.loader, TYPE_CLASSES[this.type]);
        this.renderer.addClass(this.loader, `k-loader-${this.themeColor}`);
        this.renderer.addClass(this.loader, SIZE_CLASSES$1[this.size]);
    }
}
LoaderComponent.decorators = [
    { type: Component, args: [{
                selector: 'kendo-loader',
                template: `
        <div class="k-loader-canvas">
            <span *ngFor="let segment of segmentCount" class="k-loader-segment"></span>
        </div>
    `
            },] },
];
/** @nocollapse */
LoaderComponent.ctorParameters = () => [
    { type: ElementRef },
    { type: Renderer2 }
];
LoaderComponent.propDecorators = {
    hostClass: [{ type: HostBinding, args: ['class.k-loader',] }],
    type: [{ type: Input }],
    themeColor: [{ type: Input }],
    size: [{ type: Input }]
};

const exportedModules = [
    LoaderComponent
];
const declarations = [
    ...exportedModules
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Loader component.
 */
class LoaderModule {
}
LoaderModule.decorators = [
    { type: NgModule, args: [{
                declarations: [declarations],
                exports: [exportedModules],
                imports: [CommonModule]
            },] },
];

const exportedModules$1 = [
    BadgeComponent,
    BadgeContainerComponent
];
const declarations$1 = [
    ...exportedModules$1
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Badge component.
 */
class BadgeModule {
}
BadgeModule.decorators = [
    { type: NgModule, args: [{
                declarations: [declarations$1],
                exports: [exportedModules$1],
                imports: [CommonModule]
            },] },
];

/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Indicators components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Indicators module
 * import { IndicatorsModule } from '@progress/kendo-angular-indicators';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, IndicatorsModule], // import Indicators module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class IndicatorsModule {
}
IndicatorsModule.decorators = [
    { type: NgModule, args: [{
                exports: [
                    BadgeModule,
                    LoaderModule
                ]
            },] },
];

/**
 * Generated bundle index. Do not edit.
 */

export { BadgeModule, BadgeComponent, BadgeContainerComponent, LoaderComponent, LoaderModule, IndicatorsModule };
